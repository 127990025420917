<template>
  <div>
    <DataTable title="Account" :headers="headers" :items="items" :options.sync="options" :loading.sync="loading"
      :items-length="totalItems" :page="page" :pageCount="pageCount" :items-per-page="itemsPerPage" :timezone="getTz()"
      :hide-add-btn="true" :show-export="true" :export-url="'user/export'" @update="fetchData" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import DataTable from '@/components/data-table/DataTable.vue'

import axios from 'axios'
import _ from 'lodash'

export default {
  components: {
    DataTable,
  },
  data: () => ({
    selected: [],
    options: {},
    loading: false,
    totalItems: 0,
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    headers: [
      { text: 'PB #', value: 'shipper_id', width: 150 },
      // { text: 'User', value: 'name_full', },
      { text: 'Name', value: 'name', width: 190 },
      { text: 'Email', value: 'email' },
      { text: 'Phone Number', value: 'phone', width: 150 },
      { text: 'Company', value: 'company', width: 190 },
      { text: 'Qualified', value: 'qualified', width: 190, sortable: false },
      { text: 'Integ', value: 'integrations', width: 85, align: 'center' },
      { text: 'Balance', value: 'postage_balance', width: 150 },
      { text: 'Spent', value: 'postage_spent', width: 135 },
      // { text: 'Printed', value: 'printed', width: 95, align: 'center', },
      // { text: 'Supreme Status', value: 'supreme', width: 150, align: 'center', },
      { text: 'USPS', value: 'usps', width: 85, align: 'center' },
      { text: 'Notes', value: 'notes', width: 85, align: 'center' },
      // { text: 'Email Verified', value: 'email_verified_at', align: 'center', width: 130, },
      { text: 'Last Login', value: 'last_login', width: 190 },
      { text: 'Created At', value: 'created_at', width: 190 },
      // { text: 'Last Login', value: 'last_login', width: 190, },
    ],
    items: [],
    searchTerm: '',
  }),

  computed: {
    ...mapGetters(['getTz']),

    exportName() {
      return `${new Date().getTime()}-users.csv`
    },
  },

  async mounted() {
    document.title = 'Accounts';

    this.$root.$on('updateData', async () => {
      await this.fetchData(this.searchTerm)
    })

    this.fetchData()
  },

  methods: {
    async fetchData(search = '') {
      try {
        if (_.isEmpty(this.options)) {
          return
        }

        this.loading = true
        if (this.$root.progressbar) {
          this.$root.progressbar.show()
        }
        this.searchTerm = search

        const token = this.$store.state.token
        const url = process.env.VUE_APP_API_URL

        const { itemsPerPage, page, sortBy, sortDesc } = this.options

        if (sortBy[0]) {
          if (sortBy[0] == 'name_full') {
            sortBy[0] = 'name'
          }
        } else {
          sortBy[0] = 'created_at'
          sortDesc[0] = true
        }

        const params = {
          kw: search,
          limit: itemsPerPage,
          page: page,
          order_by: sortBy[0],
          order_direction: sortDesc[0] ? 'desc' : 'asc',
          tz: this.getTz(),
        }

        const {
          data: { data, meta },
        } = await axios.get(`${url}/api/user`, {
          headers: { Authorization: `Bearer ${token}` },
          params: params,
        })

        this.page = meta.current_page
        this.pageCount = meta.last_page
        this.totalItems = meta.total
        this.itemsPerPage = meta.per_page

        this.items = await Promise.all(
          data.map(async u => {
            const postageBalance = await this.fetchPostageBalance(u.id)

            return {
              id: u.id,
              shipper_id: u.shipper_id,
              name: u.name,
              email: u.email,
              phone: u.phone,
              company: u.company,
              qualified: { q: u.q, nq: u.nq },
              postage_spent: u.postage_spent ? `$${u.postage_spent.toLocaleString('en-US')}` : '',
              printed: u.printed ? 1 : 0,
              supreme: u.supreme,
              usps: u.usps,
              notes: u.notes,
              activities: u.activities,
              integrations: u.integrations,
              email_verified_at: u.email_verified_at,
              last_login: u.last_login,
              created_at: u.created_at,
              suspend: u.suspend,
              postage_balance: postageBalance,
            }
          }),
        )
      } catch (error) {
        console.error('error', error)
      } finally {
        this.loading = false
        if (this.$root.progressbar) {
          this.$root.progressbar.hide()
        }
      }
    },
    async fetchPostageBalance(userId) {
      console.log('This is fired!')
      try {
        //* Postage Balance
        const integ_url = process.env.VUE_APP_INTEG_API_URL
        const { data } = await axios.get(`${integ_url}/user-balance`, {
          params: { uid: userId },
        })

        console.log(integ_url, 'integ_url')
        console.log(data, 'data')

        return data && data.availableBalance ? `$${data.availableBalance.toLocaleString('en-US')}` : '$0'
      } catch (error) {
        console.error('error', error)
        return '$0'
      }
    },
  },
}
</script>
